import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/layout"
import FullScreenSection from "../components/full-screen-section"
import Seo from "../components/seo"

import styled from 'styled-components'

const IndexPage = () => {

  const data = useStaticQuery(
    graphql`
      query {
        bg1:

        file(relativePath: { eq: "bg-3d-1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        bg2:
        file(relativePath: { eq: "bg-3d-2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

      }
    `
  )

  // const data2 = useStaticQuery(
  //   graphql`
  //     query {
  //       desktop: file(relativePath: { eq: "obj.jpg" }) {
  //         childImageSharp {
  //           fluid(quality: 90, maxWidth: 1920) {
  //             ...GatsbyImageSharpFluid_noBase64
  //           }
  //         }
  //       }
  //     }
  //   `
  // )  

  const bg1 = data.bg1.childImageSharp.fluid
  const bg2 = data.bg2.childImageSharp.fluid

  React.useEffect(() => {
    let iframes = document.querySelectorAll('iframe');
    // console.dir(iframes[1].clientHeight);
    iframes[0].style.height = iframes[1].clientHeight * 2 + 'px';
  }, [])

  return (
    <Layout>
      <Seo title="Experiencia 3D" />

{/*      <FullScreenSection>

        <BackgroundImage
          Tag="section"
          className={'container-fluid fullheight-ribbon d-flex flex-column'}
          fluid={bg1}
          backgroundColor={`#040e18`}
        >

          <div className={`row d-flex flex-1`}>

            <div className={`col pt-1 d-flex flex-column`}>

              <div className={`col-lg-6 col-xl-6 col`}>

                <h1 className={`text-white text-end bg-black mt-5 pb-0 mb-0`}>Experiencia 3D</h1><p className={`text-black text-end pt-0 mt-0`}>Entorno 3d donde el usuario puede visualizar la obra de Bonati, Vial y Ortúzar, con el color y la textura del proyecto original.</p>
                

              </div>

              <div className={`col d-flex flex-1 flex-column flex-items-middle space-between`}>

                <div className={`col-xl-3 col-lg-3 col`}><p className={`text-right mb-0 pb-0`}><span className={`text-white text-end bg-black pb-0 mb-0 me-1 fs-4`}>Modelo 3D</span></p><span className={`text-white fs-6`}>Desplazate por el mural del paso inferior Santa Lucía desde una escala peatonal.</span></div>
                <div className={`col-xl-3 col-lg-3 offset-lg-9 offset-xl-9 col`}><p className={`text-right mb-0 pb-0`}><span className={`text-white text-end bg-black pb-0 mb-0 me-2 fs-4`}>Recorridos 360</span></p><span className={`text-white fs-6`}>Visualizar los videos realizados con el modelo 3D haciendo recorridos en velocidad por Diagonal Paraguay y la calle Carmen.</span></div>
                <div className={`col-xl-3 col-lg-3 offset-lg-7 offset-xl-7 col pb-5`}><p className={`text-right mb-0 pb-0`}><span className={`text-white text-end bg-black pb-0 mb-0 ms-5 fs-4 mb-5`}>Vistas 360</span></p><span className={`text-white fs-6`}>Desde un punto fijo, sin velocidad observar el entorno desde ciertos ángulos.</span></div>

              </div>
            </div>                        

          </div>

          <div className={`row ribbon no-gutters`}>

            <div className={`col-3 ribbon-blue`}></div>
            <div className={`col-2 ribbon-red`}></div>
            <div className={`col-4 ribbon-navy`}></div>
            <div className={`col-3 ribbon-gray`}></div>

          </div>

        </BackgroundImage>

      </FullScreenSection>*/}

      <FullScreenSection>

        <BackgroundImage
          Tag="section"
          className={'container-fluid fullheight-ribbon d-flex flex-column'}
          fluid={bg2}
          backgroundColor={`#040e18`}
        >

          <div className={`row d-flex flex-column flex-1 items-end`}>
          <p className={`text-white text-center headline pb-3`} ><span className={`bg-black`}>Experiencia 3D</span></p>
          </div>

          <div className={`row d-flex flex-1`}>

            

            <div className={`d-flex flex-1 threed-container`}>

              <div className={`col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex flex-1 flex-column flex-items-middle`}>
                <p className={`text-white text-center ps-1 fs-4 mb-0 pb-0 responsive-bg-fix-white bg-black-71`}>Modelo 3D</p>
                <p className={`text-center`}><span className={`text-white text-center ps-1 mb-0 pb-0 responsive-bg-fix-white bg-black-71 mt-1 mb-1`}><i>Desplázate por la volumetría del mural del paso inferior Santa Lucía.</i></span></p>
                <iframe className={`iframe-sketchfab`} title="Mural del Paso Inferior Santa Lucía 3D." frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/10b9720ec26142bd9d314c1ca58d48d9/embed"></iframe>
              </div>

              <div className={`col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex flex-1 flex-column flex-items-middle p-3`}>
                <p className={`text-white text-center ps-1 fs-4 mb-0 pb-0 responsive-bg-fix-white bg-black-71`}>Recorridos 360</p>
                <p className={`text-center`}><span className={`text-white text-center ps-1 mb-0 pb-0 responsive-bg-fix-white bg-black-71 mt-1 mb-1`}><i>Videos realizados con el modelo 3D haciendo recorridos en velocidad.</i></span></p>

                <p className={`d-md-none d-lg-none d-xl-none text-white text-center ps-1 mb-0 pb-0 responsive-bg-fix-white`}><a href={"https://www.youtube.com/watch?v=70ufl-Yx5g4"}>Diagonal Paraguay</a></p>
                <p className={`d-md-none d-lg-none d-xl-none text-white text-center ps-1 mb-0 pb-0 responsive-bg-fix-white`}><a href={"https://www.youtube.com/watch?v=c0EpyPh3S_o"}>Carmen</a></p>

                <iframe className={`d-sm-none d-none d-md-block d-lg-block d-xl-block`} src="https://www.youtube.com/embed/70ufl-Yx5g4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <iframe className={`d-sm-none d-none d-md-block d-lg-block d-xl-block`} src="https://www.youtube.com/embed/c0EpyPh3S_o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

              </div>

              <div className={`col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex flex-1 flex-column flex-items-middle p-3`}>
                <p className={`text-white text-center ps-1 fs-4 mb-0 pb-0 responsive-bg-fix-white bg-black-71`}>Vistas 3D</p>
                <p className={`text-center`}><span className={`text-white text-center ps-1 mb-0 pb-0 responsive-bg-fix-white bg-black-71 mt-1 mb-1`}><i>Desde un punto de vista peatonal observamos el entorno.</i></span></p>

                <p className={`d-md-none d-lg-none d-xl-none text-white text-center ps-1 mb-0 pb-0 responsive-bg-fix-white`}><a href={"https://www.youtube.com/watch?v=bXzwPUyu6BE"}>Vista peatonal Plazoleta</a></p>
                <p className={`d-md-none d-lg-none d-xl-none text-white text-center ps-1 mb-0 pb-0 responsive-bg-fix-white`}><a href={"https://www.youtube.com/watch?v=AlF5mga00kw"}>Vista peatonal Puente</a></p>

                <iframe className={`d-sm-none d-none d-md-block d-lg-block d-xl-block`} src="https://www.youtube.com/embed/bXzwPUyu6BE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <iframe className={`d-sm-none d-none d-md-block d-lg-block d-xl-block`} src="https://www.youtube.com/embed/AlF5mga00kw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

              </div>

            </div>

          </div>

          <div className={`row d-flex`}>
              <div className={`col d-flex flex-1`}>
                <div className={`d-flex flex-1 pb-3 pr-1 items-end`}>
                    <a target="_blank" href="https://www.youtube.com/channel/UCS9cDb0TFcAWzXqpmwTZzgw"><i className={`text-white fab fa-youtube display-5`}></i></a>
                    <a target="_blank" href="https://sketchfab.com/curvadelaluz"><i className={`text-white fas fa-cube display-5`}></i></a>
                </div>
              </div>            
          </div>
            
            <div className={`row ribbon no-gutters`}>

              <div className={`col-3 ribbon-blue`}></div>
              <div className={`col-2 ribbon-red`}></div>
              <div className={`col-4 ribbon-navy`}></div>
              <div className={`col-3 ribbon-gray`}></div>

            </div>

        </BackgroundImage>

      </FullScreenSection>

    </Layout>
  )

}

const StyledBackgroundSection = styled(IndexPage)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default IndexPage
